.services-section {
    padding-inline: 7.6%;
    padding-bottom: 75px;

    /* @media only screen and (max-width: 768px) {
        padding-inline: 0;
        padding-bottom: 0;
    } */
}

.all-services-card-div {
    display: flex;
    gap: 25px;
    /* @media only screen and (max-width: 768px) {
        flex-direction: column;
        gap: 0;
    } */
}

.services-column-left, .services-column-right {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.service-card {
    display: flex;
    height: auto;
    min-height: 400px;
    width: 100%;
    margin-bottom: 22px;
    text-align: center; 

    /* @media only screen and (max-width: 768px) {
        flex-direction: column;
        margin-bottom: 24px;
    } */
}

.service-card-2, .service-card-5 {
    flex-direction: row-reverse;
    
    /* @media only screen and (max-width: 768px) {
        flex-direction: column;
        
    } */
}

.service-card-text-div {
    display: flex;
    flex-direction: column;
    background-color: #28338A;
    justify-content: center;
    flex: 1;
    width: 50%;
    height: auto;
    padding: 10px 3% 10px 3%;

    /* @media only screen and (max-width: 768px) {
        width: 100%;
        aspect-ratio: 1/1;
    } */
}

.service-card img {
    width: 50%;
   
    /* @media only screen and (max-width: 768px) {
        width: 100%;
        aspect-ratio: 1/1;
    } */
}

.service-card h2, .service-card p {
    color: #FFFFFF;
}

.service-card h2 {
    font-family: "Mango-Black";
    font-size: clamp(18px, 2vw, 24px);
    width: 100%;
    padding-bottom: 15px;
    border-bottom: 2px solid #F8C7CD;
    margin-bottom: 25px;
    margin-top: 0;

    /* @media only screen and (max-width: 768px) {
        font-size: 24px;
    } */
}

.service-card p {
    font-size: clamp(14px, 1vw, 16px);
    margin: 0;
    line-height: 22px;

    /* @media only screen and (max-width: 768px) {
        font-size: 16px;
    } */
}

/* faire mediaquerries sous 1224px */
/* faire mediaquerries sous 1024px tablette*/
/* faire mediaquerries sous 768px mobile*/ 

@media only screen and  (min-width:769px) and (max-width: 1024px) {
    .all-services-card-div {
        flex-direction: column;
    }

    .service-card-4, .service-card-6 {
        flex-direction: row-reverse;
    }

    .service-card-5 {
        flex-direction: row;
    }
}

@media only screen and (max-width: 768px) {
    .services-section {
        padding-inline: 0;
        padding-bottom: 0;
    }

    .all-services-card-div {
        flex-direction: column;
        gap: 0;
    }

    .service-card {
        flex-direction: column;
        margin-bottom: 24px;
    }

    .service-card-2, .service-card-5 {
        flex-direction: column;
    }

    .service-card-text-div {
        width: 100%;
        aspect-ratio: 1/1;
    }

    .service-card img {
        width: 100%;
        aspect-ratio: 1/1;
    }

    .service-card h2 {
        font-size: 24px;
    }

    .service-card p {
        font-size: 16px;
    }
}
.contact-main-div {
    display: flex;
    flex-direction: column;
}

.contact-map-section {
    display: flex;
    margin-top: 85px;
    margin-left: 12.5%;
    margin-right: 15%;
    margin-bottom: 89px;
}

.contact-div {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.tel-socialmedia-div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 49.5px;
}

.contact-icon {
    display: flex;
    align-items: center;
    text-align: center;
    gap: 10px;
}

.contact-main-div span, .contact-main-div p {
    color: #1D1D1B;
}

.contact-main-div .social-div a {
    color: #F8C7CD;
    margin-left: 15px;
}

.contact-main-div p {
    margin: 0 21.3% 0 21.3%;
    height: 139px;
    text-align: center;
    font-size: 12px;
    line-height: 20px;
}

.form-header-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.form-header-div span {
    color: #737373;
}

.contact-form {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
}

.contact-form input {
    display: flex;
    flex-grow: 1;
    height: 47px;
}

.contact-form textarea, .contact-form input {
    font-size: 16px;
    font-family: "Gotham-HTF-Book";
    padding-left: 18px;
    background-color: #F8C7CD;
    border: none;
}

.contact-form textarea {
    width: 100%;
    height: 190px;
    padding-top: 14px;
}

.btn-submit {
    width: 208px;
    height: 49px;
    border-radius: 8px;
    background-color: #28338A;
    border: none;
    font-family: "Mango-Black";
    font-size: 18px;
    color: #FFFFFF;
    margin-left: 69%;
}

.btn-submit:hover {
    cursor: pointer;
}

.contact-main-div h2 {
    margin: 0;
    font-family: "Mango-Black";
    font-size: 24px;
    line-height: 48px;
    color: #28338A;
}

.map-div {
    padding-left: 11%;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.map-div span {
    margin-bottom: 6px;
}

.map {
    width: 100%;
    height: 424px;
    border: 8px solid #F8C7CD;
    margin-top: 40px;
}

.map iframe {
    width: 100%;
    height: 100%;
    border: none;
}

@media only screen and (max-width: 1024px) {
    .contact-map-section {
        margin-inline: 25px;
    }
}

@media only screen and (max-width: 768px) {
    .contact-map-section {
        flex-direction: column;
        text-align: center;
        margin-top: 61px;
        margin-bottom: 40px;
        margin-inline: 0;
    }

    .contact-map-div {
        padding-inline: 0;
    }

    .contact-div {
        padding-inline: 14px;
    }

    .contact-div span {
        margin-bottom: 5px;
    }

    .contact-icon {
        justify-content: center;
    }

    .tel-socialmedia-div {
        justify-content: center;
    }

    .social-div {
        display: none;
    }

    .form-header-div {
        flex-direction: column;
        margin-bottom: 20px;
    }

    .btn-submit {
        height: 62px;
        width: 100%;
        margin-inline: 0;
    }

    .map-div {
        padding-inline: 0;
        margin-top: 64px;
    }

    .contact-main-div p {
        margin-inline: 14px;
    }
}
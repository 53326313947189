.slideshow {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 9;
    overflow: hidden;
    background-color: black;
    height: calc(100vh - 70px);
}

.slideshow img {
    width: 100%;
    height: 100%
}

.active {
    transition: 0.5s;
    opacity: 1;
    width: 100%;
}

.slideshow button {
    background-color: transparent;
    outline: none;
    border: none;
    width: auto;
}

.arrow {
    font-size: 30px;
    width: 30px;
    position: absolute;
    color: rgb(255, 255, 255);
    cursor: pointer;
    top: 50%;
}

.arrow.left {
    left: 140px;
}

.arrow.right {
    right: 140px;
}

.overlay-caroussel-div {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 250px;
    height: 100%;
    text-align: center;
}

.overlay-caroussel-div img {
    width: 250px;
    object-fit: contain;
}

.accueil-h2 {
    color: white;
    font-family: "Gotham-HTF-Book";
    font-weight: 100;
    width: 100%;
    margin-top: 0;
    font-size: clamp(1rem, 20px, 20px); 
}

.carousel-link {
    position: absolute;
    bottom: 49px;
    color: #28338A;
    text-decoration: none;
    font-size: 18px;
    height: 49px;
    width: 208px;
    background-color: #ffff;
    text-align: center;
    padding-top: 10px;
    border-radius: 8px;
}

.carousel-link:hover {
    transform: scale(1.1);
    transition: 0.1s ease-in-out;
}

.bulletpoint {
    position: absolute;
    left: 140px;
    bottom: 63px;
    color: white;
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;
}

@media only screen and (max-width: 768px) {
    .overlay-caroussel-div img {
        width: 200px;
    }

    .carousel-link {
        width: 85vw;
    }

    .carousel-link:hover {
        transform: none;
    }

    .confiance-mobile-section .pink-titles {
        font-size: 20px;
        margin-top: 16px;
    }
}
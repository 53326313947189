header {
    display: flex;
    position: fixed;
    top: 0;
    align-items: center;
    z-index: 20;
    background-color: white;
    width: 100vw;
    justify-content: space-between;
    height: 70px;
    padding-left: 7.505%;
    padding-right: 7.725%;
}

header nav {
    height: 27px;
    display: flex;
}

header nav a {
    margin-left: 45px;
    height: 100%;
    font-size: 18px;
    line-height: 20px;
    padding-inline: 4px;
    text-decoration: none;
    color: #28338A;
}

header nav a:hover {
    box-shadow: 0 -12px #F8C7CD inset;
    transition: 0.2s ease;
}

header nav a.active {
    box-shadow: 0 -12px #F8C7CD inset;
}

.logo-accueil {
    width: 50px;
    height: 50.1px;
}

.logo-accueil img {
    width: 100%;
}

.menu-toggle {
    display: none;
    border: none;
    font-size: 35px;
    background-color: white;
    color: #28338A;
    transform: rotate(180deg);
    transition: transform 0.5s ease-in-out;
}

.menu-toggle:focus {
    transform: rotate(360deg);
}

@media only screen and (max-width: 1024px) {
    header nav a:hover {
        box-shadow: none;
    }

    header nav a.active {
        transition: none;
    }
}

@media only screen and (max-width: 930px) {
    .logo-accueil {
        width: 35px;
        height: 35.06px;
    }
    
    .menu-toggle {
        display: flex;
    }

    .menu-toggle-link:hover {
        box-shadow: 0 -14px #F8C7CD inset;
    }
    header nav {
        display: none;
    }

    .menu-toggle-link {
        font-size: 24px;
        margin-inline: auto;
        margin-bottom: 55px;
        box-shadow: 0 -15px #F8C7CD inset;
        height: 24px;
    }

    .menu-toggle-link.active {
        box-shadow: 0 -14px #F8C7CD inset;
        width: auto;
        height: 24px;
    }

    header nav.active-toggle-menu {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        text-align: center;
        z-index: 20;
        position: absolute;
        top: 70px;
        left: 0;
        width: 100%;
        height: calc(100vh - 70px) ;
        background-color: white;
    }

    .active-toggle-menu-links-div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
    }

    header .social-div {
        display: flex;
        width: 100%;
        box-shadow: none;
        justify-content: space-between;
        margin-bottom: 60px;
    }

    header .social-div a {
        margin-inline: auto;
        font-size: 35px;
        height: fit-content;
    }
}

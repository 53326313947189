.extra-youtube-content {
    display: flex;
    width: 100%;
    height: 628px;
    margin-bottom: 80px;
}

.video-1-residence-div .extra-youtube-content {
    margin-bottom: 0;
}

.extra-youtube-content iframe {
    width: 100%;
    height: 100%;
}

.extra-youtube-content.atelier-youtube {
    margin: 80px 7.4% 80px 7.4%;
    width: auto;
}

/* pour adapter la hauteur de la div contenant les 2 videos YT côte à côte en format desktop */
@media only screen and (min-width: 1025px) {
    .projets-realises-div .extra-youtube-content {
        height: 360px;
        margin-bottom: 0;
    }
}

@media only screen and (max-width: 1024px) {
    .projets-realises-div .extra-youtube-content iframe {
        width: 100%;
        height: 100%;
    }
}

@media only screen and (max-width: 768px) {
    .extra-youtube-content {
        height: 360px;
        margin-bottom: 15px;
    }

    .extra-youtube-content.atelier-youtube {
        margin-inline: 0;
    }

}
.blue-button-div {
    display: flex;
    justify-content: center;
}

.blue-button {
    width: auto;
    padding-inline: 45px;
    height: 90px;
    background-color: #28338A;
    border: none;
    border-radius: 45px;
    font-size: clamp(22px, 4vw, 32px);
}

.blue-button a {
    color: white;
}

.blue-button:hover {
    transform: scale(1.05);
    transition: 0.3s;
}

.reservation-atelier, .reservation-stage {
    margin-bottom: 150px;
}

@media only screen and (max-width: 1024px) {
    .reservation-atelier, .reservation-stage {
        margin-top: 20px;
    }

    .reservation-atelier {
        margin-bottom: 50px;
    }

    .reservation-stage {
        margin-bottom: 100px;
    }
}

@media only screen and (max-width: 768px) {
    .blue-button {
        padding-inline: 15px;
    }
}
@-webkit-keyframes loader_closing {
    100% {
      opacity: 0;
      visibility: hidden;
    }
}

@keyframes loader_closing {
    100% {
      opacity: 0;
      visibility: hidden;
    }
}

.loader-div {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: #2B3B8B;
    z-index: 20;
    display: flex;
    justify-content: center;
    opacity: 1;
    visibility: visible;
    align-items: center;
    -webkit-animation: loader_closing 0.5s 1.5s linear forwards;
            animation: loader_closing 0.5s 1.5s linear forwards;
}

.loader-div img {
    width: 585px;
    height: auto;
    margin-top: 2%;
}

@media only screen and (max-width: 768px) {
    .loader-div img {
        width: 465px;
        margin-top: 10%;
    }
}
.projets-section {
    margin-inline: 9.6%;
}

.projets-top-duo-div {
    display: flex;
    width: 100%;
    height: auto;
    gap: 32px;
}

.projets-scroll-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-top: 52px;
    padding-bottom: 100px;
}

.projets-scroll-div span {
    color: #1D1D1B;
    font-size: 14px;
}

.scroll-icon {
    transform: rotate(180deg);
    color: #F8C7CD;
    font-size: 24px;
    margin-top: 10px;
}

.projet-card {
    position: relative;
    background-color: black;
    display: flex;
    width: 100%;
    height: auto;
}

.projet-card a {
    width: 100%;
    display: flex;
}

.projet-card img {
    height: 100%;
    max-width: 100%;
    flex-grow: 1;
}

.projet-card-infos {
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 0;
    width: 100%;
}

.projet-top-card .projet-card-infos {
    padding-left: 8.7%;
    padding-bottom: 7.5%;
}

.projet-large-card {
    margin-bottom: 80px;
}

.projet-large-card .projet-card-infos {
    padding-left: 4.25%;
    padding-bottom: 3.15%;
}

.projets-six-cards-div .projet-card-infos {
    padding-left: 9.2%;
    padding-bottom: 8.5%;
    padding-right: 11%;
}

.projets-six-cards-div {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 75px;
    gap: min(80px);
}

.projets-six-cards-div .projet-card {
    width: 29.65%;
    height: auto;
    flex: 1;
    min-width: 330px;
    max-width: 500px;
}


.projet-card h2, .projet-card h3 {
    color: #FFFFFF;
}

.projet-top-card h2, .projet-large-card h2 {
    max-width: 270px;
}

.projet-card h2 {
    font-size: clamp(20px, 1.5vw, 24px);
    line-height: 36px;
    padding-bottom: 13px;
    margin: 0;
    width: 100%;
    border-bottom: 2px solid #f9bcc3;
}

.projet-card h3 {
    font-size: 16px;
    line-height: 18px;
    font-family: "Gotham-HTF-Book";
    font-weight: 100;
    margin: 25px 0 0 0;
}

@media only screen and (min-width: 1920px) {
    .projets-six-cards-div .projet-card{
        min-width: 400px;
    }
}

/* Cet élément fictif prendra tout l'espace restant pour adapter une card seule aux memes dim */
@media only screen and (max-width:1400px) {
    .projets-six-cards-div:after {
        content: "";
        flex: auto;
    }
}

@media only screen and (max-width: 1024px) {
    .projets-section {
        margin-inline: 5%;
    }

    .projet-card h3 {
        margin-top: 20px;
    }

    .projet-top-card h2, .projet-large-card h2 {
        margin-inline: 0;
        max-width: 100%;
    }

    .projets-section .projet-card-infos {
        padding-inline: 16px;
        padding-bottom: 24px;
    }

    .projets-six-cards-div {
        gap: min(30px);
    }
    
    .projets-six-cards-div .projet-card {
        min-width: 320px;
    }
}

@media only screen and (max-width: 768px) {
    .projets-section {
        margin-inline: 0;
    }
    
    .projets-top-duo-div, .projets-six-cards-div {
        flex-direction: column;
        gap: 0;
        margin-top: 0;
        margin-bottom: 0;
        width: 100%;
    }

    .projets-scroll-div {
        padding: 25px 0 25px 0;
    }

    .projets-six-cards-div .projet-card {
        min-width: 100%;
        height: auto;
        aspect-ratio: 1/1;
    }

    .projet-card {
        margin-bottom: 16px;
    }

    .projet-card a {
        width: 100%;
    }

    .projet-card img {
        width: 100%;
        height: auto;
        aspect-ratio: 1/1;
    }
}
.membres-section {
    position: relative;
    padding-bottom: 182px;
    padding-inline: 140px;
    height: auto;
    z-index: 0;
}

.top-members-div, .bottom-members-div {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.membre-card {
    display: flex;
    flex-direction: column;
    width: 230px;
    height: 230px;
    position: relative;
}

.membre-card:hover {
    cursor: pointer;
}

.membre-card img {
    width: 230px;
    height: 230px;
}

.membre-card h2 {
    text-align: center;
    margin: 0;
    font-size: 18px;
    color: #28338A;
    font-family: "Mango-Black";
    margin-top: -30px;
}

.membre-clicked-div {
    z-index: 5;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    background: rgba(40, 51, 138, 0.8)
}

.membre-opened-card {
    position: fixed;
    display: flex;
    margin-top: 50px;
    margin-bottom: 100vh;
    padding: 50px 50px 50px 57px;
    width: 904px;
    height: 505px;
    background-color: #FFFFFF;
    border-radius: 8px;
}

.membre-clicked-div:hover {
    cursor: pointer;
}

.membre-opened-card p, .membre-opened-card span, .membre-opened-card li{
    color: #1D1D1B;
    font-size: 15px;
    font-family: "Gotham-HTF-Book";
    margin: 0;
}

.membre-opened-card h3, .membre-opened-card h1 {
    color: #28338A;
    font-size: 32px;
    font-family: "Mango-Black";
    margin: 0 0 20px 0;
}

.membre-opened-card img {
    width: 304px;
    height: 406px;
}

.membre-opened-card-text-div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 55px;
}

.membre-links-list {
    list-style: none;
    margin-bottom: 0;
    padding: 0;
}

.closing-cross {
    position: absolute;
    right: 32px;
    top: 35px;
    font-size: 25px;
    color: #28338A;
}

@media only screen and (max-width: 1024px) {
    .membres-section {
        padding-inline: 50px;
    }

    .membre-clicked-div {
        height: 100%;
        padding-top: 80px;
    }

    .membre-opened-card {
        position: relative;
        flex-direction: column;
        justify-content: center;
        margin-top: 0;
        margin-bottom: 0;
        padding: 20px;
        width: 70%;
        height: auto;
        background-color: #FFFFFF;
        border-radius: 8px;
    }
    
    .membre-opened-card img {
        width: 100%;
        height: auto;
        border-radius: 8px 8px 0 0;
    }

    .membre-opened-card-text-div {
        align-items: center;
        margin-inline: auto;
        margin-top: 0;
        padding: 28px;
    }

    .membre-opened-card-text-div :not(:first-child) {
        text-align: center;
    }

    .membre-top-infos {
        display: flex;
        flex-direction: column;
    }

    .membre-bouton-retour {
        width: 312px;
        height: 62px;
        border-radius: 8px;
        background-color: #283389;
        display: flex;
        justify-content: center;
        flex-direction: column;
        margin-top: 80px;
        margin-bottom: 43px;
    }

    .membre-bouton-retour span {
        font-size: 18px;
        color: white;
        font-family: "Mango-Black";
    }

}

@media only screen and (max-width: 768px) {
    .membres-section {
        padding-inline: 0;
        padding-bottom: 12px;
        position: relative;
    }

    .top-members-div, .bottom-members-div {
        flex-direction: column;
    }

    .membre-card {
        margin-bottom: 24px;
    }

    .membre-opened-card {
        position: relative;
        width: 100%;
        height: fit-content;
        padding: 0;
        margin-top: 70px;
        border-radius: 0;
    }

    .membre-opened-card img {
        border-radius: 0;
    }
}
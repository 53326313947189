:root {
  -main-blue: #28338A;
  -pink: #F8C7CD;
  -dark-blue: #1F2254;
  -white: #FFFFFF;
  background-color: #FFFFFF;
}

@font-face {
  font-family: "Mango-Black";
  src: local("black-mango-black"), url("./fonts/black-mango-black.ttf") format("truetype");
}

@font-face {
  font-family: "Gotham-HTF-Book";
  src: local("gotham-htf-book"), url("./fonts/gotham-htf-book.ttf") format("truetype");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

main {
  padding-top: 70px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
}

p, span {
  color: #FFFFFF;
  font-family: "Gotham-HTF-Book";
  font-size: 16px;
}

a {
  font-family: "Mango-Black";
  text-decoration: none;
}

img {
  object-fit: cover;
}

.pink-border-main-div::before {
  content: "";
  position: fixed;
  z-index: 25;
  height: 10px;
  width: 100vw;
  background-color: #F8C7CD;
}

.pink-titles {
  box-shadow: 0 -16px #F8C7CD inset;
  color: #28338A;
  line-height: 35px;
  font-family: "Mango-Black";
  font-size: clamp(22px, 4vw, 32px);
  padding-inline: 9px;
  margin: 71px auto auto auto;
  width: fit-content;
  text-align: center;
}

.social-div a {
  height: 25px;
  font-size: 25px;
  display: flex;
}

.social-div {
  display: flex;
}

.projet-content h2::before {
  content: "";
  position: absolute;
  width: 275px;
  height: 2px;
  background-color: #F8C7CD;
}

.description-pages {
    color: #1D1D1B;
    font-size: clamp(16px, 2vw, 16px);
    margin-inline: 18%;
    text-align: center;
    margin-top: 48px;
    margin-bottom: 48px;
}

.projets-section ,.membres-section, .services-section, .contact-main-div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.active {
  transition: none;
}

@media only screen and (max-width: 768px) {
  .description-pages {
    margin-inline: 32px;
  }

  .pink-titles {
    margin-top: 40px;
    margin-inline: 15px;
    font-size: 24px;
    padding-inline: 10px;
    line-height: 30px;
    box-shadow: 0 -13px #F8C7CD inset;
  }
}
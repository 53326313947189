.projet-bannerImg {
    max-height: 628px;
    min-height: 360px;
    margin-top: 70px;
    width: 100%;
}

.projet-content {
    margin-inline: 7.4%;
    display: flex;
    flex-direction: column;
}

.projet-content img {
    min-height: 360px;
}

.projet-content h1, .residence h3 {
    margin-left: 0;
}

.residence h3 {
    margin-bottom: 50px;
}

.projet-content h2 {
    position: relative;
    font-family: "Gotham-HTF-Book";
    font-size: 16px;
    margin-top: 50px;
    margin-bottom: 32px;
}

.projet-content h2::before {
    top: -16px;
}

.projet-description {
    width: 70%;
    margin-bottom: 111px;
    margin-top: 0;
}

.visite-virtuelle-div .projet-description, .projets-realises-div .projet-description, .savoir-plus-div .projet-description {
    margin-bottom: 70px;
}

.projet-description p {
    color: black;
    font-size: 20px;
    /* text-align: justify; */
}

.projet-description .creditsPhoto {
    font-size: 17px;
}

.projet-duo-images-div {
    display: flex;
    justify-content: space-between;
    gap: min(15px);
    text-align: right;
    max-height: 690px;
    margin-bottom: 58px;
}

.residence .projet-duo-images-div {
    margin-bottom: 15px;
}

.video-1-residence-div, .visite-virtuelle-div, .projets-realises-div, .savoir-plus-div {
    display: flex;
    flex-direction: column;
}

.savoir-plus-div {
    margin-bottom: 85px;
}

.projets-realises-div {
    margin-top: 40px;
}

.projets-realises-videos-div {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 25px;
}

/* Cible la première .projets-realises-videos-div dans sa position réelle */
.projets-realises-div > .projets-realises-videos-div:nth-of-type(2) {
    margin-bottom: 25px;
}

.projet-duo-images-div img {
    max-height: 628px;
    width: 100%;
    height: auto;
}

.projet-left-image-div, .projet-right-image-div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
}

.projet-left-image-div p::after, .projet-right-image-div p::after {
    content: "";
    position: absolute;
    width: 29.5%;
    height: 2px;
    background-color: #F8C7CD;
    bottom: 0;
    right: 0;
}

.projet-duo-images-div p {
    margin-top: 25px;
    margin-bottom: 16px;
    padding-left: 20px;
    width: 100%;
    height: auto;
    flex-wrap: nowrap;
    font-size: clamp(12px, 1.5vw, 12px);
    color: black;
}

.projet-lastImg {
    display: flex;
    width: 100%;
    margin-bottom: 80px;
}

.extraDescription p {
    font-weight: 600;
    font-style: italic;
    font-size: 18px;
}

.extraDescription p:last-child {
    margin-bottom: 0;
}

.extraDescription a, .babayagaInfos a {
    color: #28338A;
}

.babayagaInfos {
    display: flex;
    justify-content: space-between;
    gap: min(30px);
    margin-bottom: 80px;
}

.babayagaInfos p, .babayagaInfos span {
    color: black;
}

.babayagaInfos span {
    font-size: 14px;
}

.babayagaInfos a {
    font-family: "Gotham-HTF-Book";
}

.babayagaInfos a {
    text-decoration: underline;
}

.babayagaInfos a, .babayagaInfos p {
    font-size: 18px;
}

.babayagaLeftColumnInfos, .babayagaRightColumnInfos {
    flex-direction: column;
}

.babayagaLeftColumnInfos {
    width: 41%;
}

@media only screen and (max-width: 1024px) {
    .projet-content {
        align-items: center;
    }

    .projet-content h1 {
        margin-inline: 5%;
    } 

    .residence h3 {
        margin-left: auto;
    }
    
    .projet-content h2 {
        width: 100%;
        text-align: center;
    }

    .projet-content h2::before {
       display: flex;
       left: 50%;
       transform: translateX(-50%);
       max-width: 70%;
    }

    .projet-description {
       text-align: center;
       font-size: 16px;
       margin-bottom: 29px;
    }

    .projet-duo-images-div {
        flex-direction: column;
        max-height: fit-content;
        margin-bottom: 15px;
    }

    .video-1-residence-div {
        width: 100%;
    }

    .visite-virtuelle-div, .projets-realises-div, .savoir-plus-div {
        align-items: center;
    }

    .projets-realises-videos-div {
        flex-direction: column;
    }

    .projet-left-image-div::after, .projet-right-image-div::after {
        display: none;
    }

    .babayagaInfos {
        flex-direction: column;
    }

    .babayagaInfos, .babayagaLeftColumnInfos, .babayagaRightColumnInfos {
        width: 100%;
    }
}

@media only screen and (max-width: 768px) {
    .projet-content {
        margin-inline: 0;
        padding-bottom: 0;
    }

    .projet-content .pink-titles {
        margin-top: 16px;
        margin-inline: 5px;
    }

    .projet-content img {
        min-height: auto;
    }

    .projet-bannerImg {
        max-height: 450px;
    }

    .projet-content h2 {
        margin-top: 29px;
        margin-bottom: 16px;
        padding-inline: 25px;
    }

    .residence .pink-titles {
        margin-inline: auto;
    }

    .visite-virtuelle-div {
        margin-top: 15px;
    }

    .projet-content h2::before {
        bottom: 28px;
    }

    .projet-duo-images-div, .video-1-residence-div {
        width: 100%;
    }

    .babayagaInfos {
        padding-inline: 20px;
    }

    .babayagaInfos p, .babayagaInfos a, .babayagaInfos span {
        font-size: 16px;
        word-wrap: break-word;
    }
}
footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 41px;
    padding-bottom: 48px;
    height: 291px;
    width: 100%;
    background-color: #28338A;
    color: #FFFFFF;
}

footer nav {
    margin-bottom: 36.5px;
    display: flex;
    width: 100%;
    justify-content: center;
    text-align: center;
}

footer nav a, footer nav a.active {
    color: #FFFFFF;
    /* margin-inline: 43.5px; */
    margin-inline: 2%;
    width: auto;
    font-size: 18px;
}

footer span {
    height: 27px;
}

footer .social-div a {
    color: #FFFFFF;
    margin-top: 32px;
    margin-inline: 7.62px;
}

@media only screen and (max-width: 768px) {
    footer {
        height: 122px;
        padding: 24px;
        justify-content: space-between;
    }

    footer span {
        font-size: 12px;
        line-height: 27px;
    }

    footer nav {
        width: 100%;
        flex-direction: column;
        height: auto;
    }

    footer nav a {
        margin-inline: 5%;
        margin-bottom: 20px;
    }

    footer .active {
        width: auto;
        transition: none;
    }
}